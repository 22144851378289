import { Navbar, Button } from "flowbite-react";
import ToolstrBlackLogo from "../assets/toolstr-black-bg-horizontal.png";
import ShopifyPartnerLogo from "../assets/ShopifyPlus_Secondary_Inverted.svg";

export const Header = () => {
  return (
    <Navbar className="bg-black sticky top-0 z-10 w-full">
      <Navbar.Brand href="/">
        <img src={ToolstrBlackLogo} className="h-10" alt="toolstr Logo" />
      </Navbar.Brand>
      <div className="flex md:order-2">
        <a
          href="https://apps.shopify.com/partners/toolstr"
          target="_blank"
          rel="noreferrer"
          className="mr-8 hidden md:block"
        >
          <img
            src={ShopifyPartnerLogo}
            width={200}
            alt="Shopify partners logo"
          />
        </a>
        <Button
          color="secondary"
          className="rounded"
          href="https://calendly.com/toolstr"
          rel="noreferrer"
          target="_blank"
        >
          Book a call
        </Button>
        {/* <Navbar.Toggle /> */}
      </div>
      {/* <Navbar.Collapse>
        <Navbar.Link href="/" active={window.location.pathname === "/"}>
          Home
        </Navbar.Link>
        <Navbar.Link
          href="/shopify"
          active={window.location.pathname === "/shopify"}
        >
          Shopify
        </Navbar.Link>
        <Navbar.Link
          href="/woocommerce"
          active={window.location.pathname === "/woocommerce"}
        >
          WooCommerce
        </Navbar.Link>
      </Navbar.Collapse> */}
    </Navbar>
  );
};
