import { Card } from "flowbite-react";
import {
  FaCode,
  FaConnectdevelop,
  FaCompass,
  FaAnglesUp,
  FaGears,
  FaHeadset,
} from "react-icons/fa6";

import SLDLogo from "../assets/ldr-asl-logo.jpg";
import SQRCLogo from "../assets/sqrc-asl-logo.jpg";
import LoopsyLogo from "../assets/loopsy-logo.jpg";
export interface OfferingsCardProps {
  title: string;
  description: string;
  icon: keyof typeof iconMap;
  link?: string;
}
const iconMap = {
  code: <FaCode className="mx-auto" size={70} />,
  develop: <FaConnectdevelop className="mx-auto" size={70} />,
  compass: <FaCompass className="mx-auto" size={70} />,
  anglesUp: <FaAnglesUp className="mx-auto" size={70} />,
  gears: <FaGears className="mx-auto" size={70} />,
  headset: <FaHeadset className="mx-auto" size={70} />,
  sld: SLDLogo,
  sqrc: SQRCLogo,
  loopsy: LoopsyLogo,
};
export const OfferingsCard = ({
  title,
  description,
  icon,
  link,
}: OfferingsCardProps) => {
  const attributes = {} as any;
  if (link) {
    attributes.href = link;
  }

  return (
    <Card className="text-center" {...attributes}>
      {typeof iconMap[icon] === "string" ? (
        <img
          src={iconMap[icon] as string}
          alt={`${title} Logo`}
          width={70}
          className="mx-auto rounded"
        />
      ) : (
        iconMap[icon]
      )}
      <h5 className="text-2xl font-bold text-black">{title}</h5>
      <p className="font-normal">{description}</p>
    </Card>
  );
};
