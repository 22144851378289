import { Hexagon } from "../assets";
import ToolstrLogoWhite from "../assets/toolstr-sq-white.jpg";
import ShopifyPartnerLogo from "../assets/ShopifyPlus_Secondary_Inverted.svg";
import { OfferingsCard, ReviewCard } from "../components";
function Home() {
  return (
    <div>
      {/* Hero */}
      <div className="relative bg-black text-white p-4 md:p-0">
        <Hexagon position="top-20 left-40" size="10px" />
        <Hexagon position="top-25 left-20" size="10px" />
        <Hexagon position="top-10 right-0" size="20px" />
        <Hexagon position="top-2 right-10" size="30px" />
        <Hexagon position="top-12 right-12" size="10px" />
        <Hexagon position="top-20 right-10" size="20px" />
        <Hexagon position="top-12 right-40" size="30px" />

        <div className="mx-auto items-center justify-between container pt-20 md:pt-40">
          <h1 className="w-full lg:w-1/2 text-6xl font-bold">
            Simple yet powerful tools for your Shopify business.
          </h1>
          <br />
          <p className="py-4 pb-40 w-full lg:w-1/2">
            Boost customer engagement, streamline your business operations, and
            increase sales with our suite of powerful apps for your online
            store.
          </p>
        </div>
      </div>
      {/* why? */}
      <div className="bg-gradient-to-b from-black from-50% to-white to-0% px-4 mb-10">
        <p className="w-3/4 md:w-1/2 mx-auto text-center font-normal text-white">
          Driven by passion and dedicated to your growth
        </p>
        <h1 className="w-full text-center text-6xl font-bold text-white pb-10">
          Why toolstr?
        </h1>
        <div className="container mx-auto">
          <div className="content-evenly grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
            <OfferingsCard
              title="No coding required"
              description="All our apps require 0 coding skills, yet allows great level of
                customizations"
              icon="code"
            />
            <OfferingsCard
              title="Seamless integration"
              description="Our apps connect seamlessly and sits right in your Shopify admin
              panel"
              icon="develop"
            />
            <OfferingsCard
              title="Omnichannel"
              description="Use our apps wherever you sell, your online store or POS. Our
              apps are available everywhere."
              icon="compass"
            />
            <OfferingsCard
              title="Continuous updates"
              description="We roll out new features and improvements every week to keep up
              with your every evolving requirements."
              icon="anglesUp"
            />
            <OfferingsCard
              title="Customizations"
              description="We are open to any customizations you need on any of our apps to
              meet your business requirements."
              icon="gears"
            />
            <OfferingsCard
              title="Bespoke Support"
              description="We are always available to help you with everything. From setup
                to customizations, we are just a message away."
              icon="headset"
            />
          </div>
        </div>
      </div>
      {/* Our Apps */}
      <div className="bg-black p-4 pb-10">
        <h1 className="w-full text-center text-6xl font-bold text-white py-10">
          Know our apps
        </h1>
        <div className="container mx-auto">
          <div className="content-evenly grid grid-cols-1 md:grid-cols-3 gap-4">
            <OfferingsCard
              title="Super Local Delivery"
              description="One stop solution to optimize your local deliveries, route
              planning, driver management, prooof of delivery, order
              management, customer notifications and much more."
              icon="sld"
              link="https://apps.shopify.com/local-delivery-routes"
            />
            <OfferingsCard
              title="Super QR Codes"
              description="Create QR codes for your products, collections, discounts and
              much more. You can even prefill your customer's cart with a QR
              code. Leverage the power of dynamic QR codes now!!"
              icon="sqrc"
              link="https://apps.shopify.com/super-qr-codes"
            />
            <OfferingsCard
              title="loopsy - stories & more"
              description="Create shoppable stories to boost engagement and increase conversion and sales. Engage your customers with captivating posts, and let them effortlessly purchase their favorite items instantly."
              icon="loopsy"
              link="https://apps.shopify.com/loopsy"
            />
          </div>
          <div className="flex justify-center mx-auto p-4">
            <a
              href="https://apps.shopify.com/partners/toolstr"
              target="_blank"
              rel="noreferrer"
            >
              <img
                alt="Shopify partners logo"
                src={ShopifyPartnerLogo}
                width={200}
              />
            </a>
          </div>
        </div>
      </div>
      {/* About us */}
      <div className="p-4 pb-10 relative">
        <Hexagon fill="#000000" size="25px" position="right-10 top-10" />
        <Hexagon fill="#000000" size="20px" position="right-20 top-25" />
        <Hexagon fill="#000000" size="18px" position="right-20 top-40" />
        <Hexagon fill="#000000" size="15px" position="right-20 top-10" />
        <Hexagon fill="#000000" size="10px" position="right-20 top-20" />
        <Hexagon fill="#000000" size="12px" position="right-10 top-20" />

        <h1 className="w-full text-center text-6xl font-bold text-black py-10">
          About us
        </h1>
        <div className="container mx-auto">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center">
            <img
              alt="toolstr logo"
              src={ToolstrLogoWhite}
              className="w-1/2 mx-auto"
            />
            <p>
              Based out of India, our small yet mighty team has embarked on a
              journey to revolutionize how businesses engage with digital
              solutions. Our suite of products designed for the Shopify app
              store, including Super QR Codes and Super Local Delivery, reflects
              our commitment to empowering businesses with tools that enhance
              their operations and customer experiences. <br />
              At toolstr, we're driven by a singular mission: to help businesses
              build unforgettable brand experiences. We believe that in the
              digital age, the interface between businesses and their customers
              is not just a transactional space but an opportunity to create
              lasting connections. Our apps are designed to bridge gaps, solve
              real-world problems, and unlock potential for businesses across
              the globe.
              <br />
              <a
                className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
                href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#116;&#111;&#111;&#108;&#115;&#116;&#114;&#046;&#099;&#111;&#109;"
                target="_blank"
                rel="noreferrer"
              >
                &#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#116;&#111;&#111;&#108;&#115;&#116;&#114;&#046;&#099;&#111;&#109;
              </a>
            </p>
          </div>
        </div>
      </div>
      {/* What Customers say */}
      <div className="bg-black p-4 pb-10">
        <h1 className="w-full text-center text-6xl font-bold text-white py-10">
          Hear from our customers
        </h1>
        <div className="container justify-start mx-auto flex flex-no-wrap overflow-x-scroll scrolling-touch items-start gap-4">
          <ReviewCard
            title="Caramelly"
            description="Best app if you want to manage last mile delivery. Vikash and his team have been outstanding in adding additional features as per our requirement."
            app="Super Local Delivery"
          />
          <ReviewCard
            title="The Christmas Tree Stand"
            description="Just started using and it seems like a good app. I'd love to be able to design the dynamic QR codes by adding our logo to it. This does not seem to be a feature yet. I also would love if there was a small white border around the logo in the center of the non-dynamic qr codes to make our logo more visible. We were able to design a qr code this way on another QR site generator, but we prefer to use something that integrates w/ shopify. ***Update: I originally gave this 4 stars cause I'd like to seem more features, but changed to 5 stars b/c customer service was really fast and helpful. They also said they'll be working on the features I requested."
            app="Super QR Codes"
          />
          <ReviewCard
            title="Well Baked Box"
            description="We love this app because it combines just the right simplicity with functionality. The founder is very helpful with requests. Also love the combo of subscription or credit based payment."
            app="Super Local Delivery"
          />
          <ReviewCard
            title="Couture Connoisseur"
            description="This was very user friendly! Easy to create codes! I loved the lil tour at the beginning and also the features that allow you to add a photo at the center of the code!"
            app="Super QR Codes"
          />
          <ReviewCard
            title="Madras Gourmet"
            description="A must to use app for merchants carrying out last mile fulfillment/local delivery by themselves. This app helped us reduce our workload in our last mile delivery phase of our order fulfillment process mainly in routing and updating our customers with notifications at each stages. Im glad to have found this app for my store."
            app="Super Local Delivery"
          />
          <ReviewCard
            title="Bucks Spices and Gourmet"
            description="I need to be able to point to a particular and simple collection of products so that people who look in my retail window and who don't want to come in can select and buy from a curated list of 'grab and go' products. I'll add alerts for these orders so that, like a take-out restaurant, these orders will be separate from our batch fulfillment process and someone can grab their order of a shelf and get it to them. I needed a bit of support due to some technicality. Support was friendly and responsive. If you need a simple but powerful QR code redirect app, this is a good one to consider!"
            app="Super QR Codes"
          />
          <ReviewCard
            title="KuxtalMarket"
            description="Literally the best local delivery app on Shopify. The routes are great, you have the capability to add in other stops if need be. You can modify the route. And the support is AMAZING. They are there to help whenever you need. I %110 recommend this app!"
            app="Super Local Delivery"
          />
          <ReviewCard
            title="NOSOLO Brand"
            description="I really like this app. Being able to automatically apply coupon codes to a cart by scanning a QR Code – absolutely brilliant. This will really help with conversions. The customer service is great too. I had a question and they quickly got me an answer. I recommend this app for those who want to drive sales with QR Codes"
            app="Super QR Codes"
          />
        </div>
      </div>
    </div>
  );
}
export default Home;
