import { Card } from "flowbite-react";
import { FiveStar } from "../components";
export interface ReviewCardProps {
  title: string;
  description: string;
  app: string;
}
export const ReviewCard = ({ title, description, app }: ReviewCardProps) => {
  return (
    <Card className="flex-none w-3/4 md:w-1/2 lg:w-1/3">
      <h5 className="text-lg font-bold text-black">{title}</h5>
      <p className="font-normal test-sm h-40 overflow-scroll">{description}</p>
      <h5 className="w-full text-right text-md text-slate-500 leading-1">
        {app}
      </h5>
      <div className="flex justify-end">
        <FiveStar />
      </div>
    </Card>
  );
};
