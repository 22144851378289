import { Rating } from "flowbite-react";
export const FiveStar = () => {
  return (
    <div className="mb-5 mt-2.5 flex items-center">
      <Rating>
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
        <Rating.Star />
      </Rating>
      <span className="ml-3 mr-2 rounded bg-black px-2.5 py-0.5 text-xs font-semibold text-white">
        5.0
      </span>
    </div>
  );
};
