import React from "react";

interface HexagonProps {
  fill?: string;
  position?: string;
  size?: string;
  rotate?: string;
}

export const Hexagon: React.FC<HexagonProps> = ({
  fill = "#ffffff",
  position,
  size = "100px",
  rotate = 90,
}) => (
  <svg
    className={`absolute transform rotate-${rotate} ${position}`}
    width={size}
    height={size}
    viewBox="0 0 1.875 1.875"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0.188 0.563v0.75l0.75 0.438 0.75 -0.438v-0.75L0.938 0.125l-0.75 0.438Z"
      stroke={fill}
      stroke-width="0.125"
    />
  </svg>
);

export default Hexagon;
