import { Flowbite, CustomFlowbiteTheme } from "flowbite-react";
import { Header, Footer } from "./components";
import { Outlet } from "react-router-dom";

function App() {
  const toolstrTheme: CustomFlowbiteTheme = {
    button: {
      color: {
        primary: "bg-black text-white hover:bg-slate-700",
        secondary: "bg-transparent text-white outline hover:bg-slate-700",
      },
    },
    navbar: {
      link: {
        base: "text-white hover:text-slate-300",
        active: {
          on: "text-white underline underline-offset-4",
          off: "text-white",
        },
      },
    },
    footer: {
      root: {
        base: "bg-black py-6",
        container:
          "mx-auto flex flex-wrap items-center justify-between container",
      },
      copyright: {
        base: "text-white",
      },
      groupLink: {
        base: "flex items-center justify-center text-white",
      },
    },
  };
  return (
    <Flowbite theme={{ theme: toolstrTheme }}>
      <Header />
      <Outlet />
      <Footer />
    </Flowbite>
  );
}

export default App;
