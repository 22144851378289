export default function Terms() {
  return (
    <div className="container mx-auto py-20">
      <h1 className="w-full text-2xl font-bold">Terms of Service</h1>
      <p className="w-full py-4">
        By accessing the website at https://toolstr.com or any of our apps, you
        are agreeing to be bound by these terms of service, all applicable laws
        and regulations, and agree that you are responsible for compliance with
        any applicable local laws. If you do not agree with any of these terms,
        you are prohibited from using or accessing this site. The materials
        contained in this website are protected by applicable copyright and
        trademark law.
      </p>
      <p className="w-full py-4">
        <h4 className="w-full text-xl font-bold">2. Use License</h4>
        Permission is granted to temporarily download one copy of the materials
        (information or software) on toolstr's website, Shopify app store for
        personal, non-commercial transitory viewing only. This is the grant of a
        license, not a transfer of title, and under this license you may not:
        modify or copy the materials; use the materials for any commercial
        purpose, or for any public display (commercial or non-commercial);
        attempt to decompile or reverse engineer any software contained on
        toolstr's website; remove any copyright or other proprietary notations
        from the materials; or transfer the materials to another person or
        "mirror" the materials on any other server. This license shall
        automatically terminate if you violate any of these restrictions and may
        be terminated by toolstr at any time. Upon terminating your viewing of
        these materials or upon the termination of this license, you must
        destroy any downloaded materials in your possession whether in
        electronic or printed format.
      </p>
      <p className="w-full py-4">
        <h4 className="w-full text-xl font-bold">3. Disclaimer</h4>
        The materials on toolstr's website are provided on an 'as is' basis.
        toolstr makes no warranties, expressed or implied, and hereby disclaims
        and negates all other warranties including, without limitation, implied
        warranties or conditions of merchantability, fitness for a particular
        purpose, or non-infringement of intellectual property or other violation
        of rights. Further, toolstr does not warrant or make any representations
        concerning the accuracy, likely results, or reliability of the use of
        the materials on its website or otherwise relating to such materials or
        on any sites linked to this site.
      </p>
      <p className="w-full py-4">
        <h4 className="w-full text-xl font-bold">4. Limitations</h4>
        In no event shall toolstr or its suppliers be liable for any damages
        (including, without limitation, damages for loss of data or profit, or
        due to business interruption) arising out of the use or inability to use
        the materials on toolstr's website, even if toolstr or a toolstr
        authorized representative has been notified orally or in writing of the
        possibility of such damage. Because some jurisdictions do not allow
        limitations on implied warranties, or limitations of liability for
        consequential or incidental damages, these limitations may not apply to
        you.
      </p>
      <p className="w-full py-4">
        <h4 className="w-full text-xl font-bold">5. Accuracy of materials</h4>
        The materials appearing on toolstr's website could include technical,
        typographical, or photographic errors. toolstr does not warrant that any
        of the materials on its website are accurate, complete or current.
        toolstr may make changes to the materials contained on its website at
        any time without notice. However toolstr does not make any commitment to
        update the materials.
      </p>

      <p className="w-full py-4">
        <h4 className="w-full text-xl font-bold">6. Links</h4>
        toolstr has not reviewed all of the sites linked to its website and is
        not responsible for the contents of any such linked site. The inclusion
        of any link does not imply endorsement by toolstr of the site. Use of
        any such linked website is at the user's own risk.
      </p>
      <p className="w-full py-4">
        <h4 className="w-full text-xl font-bold">7. Modifications</h4>
        toolstr may revise these terms of service for its website at any time
        without notice. By using this website you are agreeing to be bound by
        the then current version of these terms of service.
      </p>
      <p className="w-full py-4">
        <h4 className="w-full text-xl font-bold">8. Governing Law</h4>
        These terms and conditions are governed by and construed in accordance
        with the laws of Maharashtra and you irrevocably submit to the exclusive
        jurisdiction of the courts in that State or location.
      </p>
    </div>
  );
}
