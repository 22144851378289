export default function Privacy() {
  return (
    <div className="container mx-auto py-20">
      <h1 className="w-full text-2xl font-bold">Privacy Policy</h1>
      <p className="w-full py-4">
        Your privacy is important to us. It is toolstr's policy to respect your
        privacy regarding any information we may collect from you across our
        website, https://toolstr.com, and other apps and sites we own and
        operate.
      </p>
      <p className="w-full py-4">
        We only ask for personal information when we truly need it to provide a
        service to you. We collect it by fair and lawful means, with your
        knowledge and consent. We also let you know why we’re collecting it and
        how it will be used.
      </p>
      <p className="w-full py-4">
        We only retain collected information for as long as necessary to provide
        you with your requested service. What data we store, we’ll protect
        within commercially acceptable means to prevent loss and theft, as well
        as unauthorized access, disclosure, copying, use or modification.
      </p>
      <p className="w-full py-4">
        We don’t share any personally identifying information publicly or with
        third-parties, except when required to by law.{" "}
      </p>
      <p className="w-full py-4">
        Our website may link to external sites that are not operated by us.
        Please be aware that we have no control over the content and practices
        of these sites, and cannot accept responsibility or liability for their
        respective privacy policies.
      </p>
      <p className="w-full py-4">
        You are free to refuse our request for your personal information, with
        the understanding that we may be unable to provide you with some of your
        desired services. Your continued use of our website will be regarded as
        acceptance of our practices around privacy and personal information.
      </p>
      <p className="w-full py-4">
        If you have any questions about how we handle user data and personal
        information, feel free to contact us at{" "}
        <a
          className="font-medium text-blue-600 dark:text-blue-500 hover:underline"
          href="mailto:&#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#116;&#111;&#111;&#108;&#115;&#116;&#114;&#046;&#099;&#111;&#109;"
          target="_blank"
          rel="noreferrer"
        >
          &#115;&#117;&#112;&#112;&#111;&#114;&#116;&#064;&#116;&#111;&#111;&#108;&#115;&#116;&#114;&#046;&#099;&#111;&#109;
        </a>
        .
      </p>
      <p className="w-full py-4">
        This policy is effective as of 2 April 2020.
      </p>
    </div>
  );
}
