import { Footer as FBFooter } from "flowbite-react";
import ShopifyPartnerLogo from "../assets/ShopifyPlus_Secondary_Inverted.svg";

export const Footer = () => {
  return (
    <div className="bg-black">
      <FBFooter container>
        <FBFooter.Copyright href="/" by="toolstr" year={2020} />
        <FBFooter.LinkGroup>
          <FBFooter.Link href="/privacy">Privacy Policy</FBFooter.Link>
          <FBFooter.Link href="/terms">Terms of service</FBFooter.Link>
          <FBFooter.Link
            href="https://apps.shopify.com/partners/toolstr"
            target="_blank"
          >
            <img
              alt="Shopify partners logo"
              src={ShopifyPartnerLogo}
              width={200}
            />
          </FBFooter.Link>
        </FBFooter.LinkGroup>
      </FBFooter>
    </div>
  );
};
